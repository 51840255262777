<template>
    <v-container>
        <v-row>
            <v-col cols=7 style="padding-bottom:0px;" ><h2 class="primary--text" style="font-weight:400;">EHS Checklists</h2> </v-col>
            <v-col cols=5 style="padding-bottom:0px;" class="text-right"><p class="primary--text mb-0">{{ now_date }}</p></v-col>
            <v-col col-12><h4 class="primary--text pt0=-0">{{ project.name }} {{ project.project_code }}</h4></v-col>
        </v-row>
        <div v-if="ehs.length > 0">
            <v-card
                elevation="2"
                outlined
                class="mb-3"
                v-for="model in ehs" v-bind:key="model.id" >
                <v-card-title>
                    <v-row>
                        <v-col cols=9><h4 class="primary--text" style="font-weight:400 !important;">{{ model.name }}</h4></v-col>
                        <v-col cols=3 style="padding-left:0px !important;"><v-btn small elevation="2" color="red">{{ updateFrequency(model.frequency) }}</v-btn></v-col>
                    </v-row>
                </v-card-title>
                <v-card-text style="padding-bottom:0px;">
                    <v-row v-for="(question, index) in model.questions" v-bind:key="question.id" style="margin-top:-20px;margin-bottom:1px;">
                        <v-col cols="9"><h4 class="black--text">{{ (index+1) }}. {{ question.name }}</h4></v-col>
                        <v-col cols="2">
                            <v-btn v-if="question.reviewed == 'none' || question.reviewed == 'reject'" x-small class="primary" :color="updateStatus(question.reviewed)" @click="reviewQuestion(model)">{{ updateStatusWord(question.reviewed) }}</v-btn>
                            <v-btn v-else x-small class="primary" :color="updateStatus(question.reviewed)">{{ updateStatusWord(question.reviewed) }}</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
        <div v-else>
            <v-alert type="error">No EHS checklists to review</v-alert>
        </div>
      
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import date from 'date-and-time'

export default {
    name: "EhsOverview",
    data: () => ({
        now_date : ""
    }),
    computed:{
        // ...mapGetters ({ project : "getCurrentProject" },{ ehs : "getAllEhs" })
        ...mapGetters (["getCurrentProject","getAllEhs" ]),
        project () {
            return this.getCurrentProject
        },
        ehs () {
            return this.getAllEhs
        }
    },
    mounted(){
        let now  = new Date();
        let now_dates = date.format(now, 'ddd, MMM DD YYYY');
        this.now_date = now_dates 
        let user = JSON.parse(localStorage.getItem('user'))
        if(user == null){
            this.$router.push('/')
        }else{
            if(user.ehs != 1){
                this.$router.push('/home')
            }else {
                var payload = this.project.project_type_id+'/'+this.project.id+'/'+user.id
                this.fetchEhs(payload)
            }
        }
        
    },
    methods: {
        ... mapActions(['fetchEhs']),
        reviewQuestion (value) {
            this.$store.commit('setCurrentEhs',value)
            this.$router.push('/checklists/ehs/review')
        },
        updateFrequency(value){
            return value.toUpperCase();
        },
        updateStatus (value){
            if(value == "pending"){
                return "warning"
            }else if(value == "approve"){
                return "success"
            }else if(value == "reject"){
                return "error "
            }else{
                return "primary"
            }
        },
        updateStatusWord (value){
            if(value == "approve"){
                return "pass"
            }else if(value == "reject"){
                return "fail"
            }else if(value == "none"){
                return "review"
            }else{
                return value
            }
        }
    }
}
</script>